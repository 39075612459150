<template>
    <SiteHead
        :tenant="tenant"
        :page="page"
    />

    <div
        v-if="globals.site_wide_banner.active && globals.site_wide_banner.position.value === 'top'"
        :class="globals.site_wide_banner.sticky && 'sticky inset-x-0 top-0'"
    >
        <SiteWideBanner />
    </div>

    <nav
        :class="[
            'text-angus fixed inset-x-0 top-0 z-40 font-[barlow]',
            'hidden md:block'
        ]"
    >
        <div class="container flex flex-col items-center justify-center">
            <div class="mt-6 flex justify-center">
                <figure class="bg-mayo-light rounded-xl p-3 shadow-xl">
                    <img
                        alt="PraticoFrigo logo"
                        class="isolate z-10 h-12 w-auto"
                        src="https://res.cloudinary.com/dd7wnkwka/image/upload/v1704746949/praticofrigo/praticofrigo-logo-yellow_naukab.svg"
                    >
                </figure>
            </div>

            <div class="bg-mayo-light -mt-6 flex justify-around gap-12 rounded-xl px-12 py-6 shadow-xl">
                <a class="text-xl font-semibold" :href="siteRoute('/#accueil')">Accueil</a>
                <a class="text-xl font-semibold" :href="siteRoute('/#produits')">Produits</a>
                <a class="text-xl font-semibold" :href="siteRoute('/#a-propos')">À propos</a>
                <a class="text-xl font-semibold" :href="siteRoute('/#contact')">Contact</a>
            </div>
        </div>
    </nav>

    <nav class="fixed inset-x-0 top-6 z-40 flex justify-center md:hidden">
        <a :href="siteRoute('/')">
            <img
                alt="PraticoFrigo Logo"
                class="block h-16"
                src="https://res.cloudinary.com/dd7wnkwka/image/upload/v1704746949/praticofrigo/praticofrigo-logo-yellow_naukab.svg"
            >
        </a>
    </nav>

    <SiteSections class="bg-mayo-light" :page="page" />

    <footer class="bg-mayo-light py-24">
        <div
            :class="[
                'container grid  items-center justify-center',
                'grid-cols-1 lg:grid-cols-3'
            ]"
        >
            <div class="flex flex-col items-center justify-center gap-24 lg:col-start-2">
                <img
                    alt="PraticoFrigo Logo"
                    class="w-auto max-w-md"
                    src="https://res.cloudinary.com/dd7wnkwka/image/upload/v1704746949/praticofrigo/praticofrigo-logo-yellow_naukab.svg"
                >
                <img
                    alt="PraticoFrigo Go Icon"
                    class="text-ketchup w-12"
                    src="https://res.cloudinary.com/dd7wnkwka/image/upload/v1704747536/praticofrigo/praticofrigo-go-red_xyytqg.svg"
                >
            </div>
        </div>
    </footer>

    <div
        v-if="globals.site_wide_banner.active && globals.site_wide_banner.position.value === 'bottom'"
        :class="globals.site_wide_banner.sticky && 'sticky inset-x-0 bottom-0'"
    >
        <SiteWideBanner />
    </div>
</template>

<script lang="ts" setup>
    import '../css/style.css';
    import { useSite } from '@/site/composables/use-site.ts';
    import { useRoute } from '@/shared/composables/use-route.ts';

    import SiteWideBanner from '@/site/components/site-wide-banner.vue';
    import SiteHead from '@/site/components/site-head.vue';
    import SiteSections from '@/site/components/site-sections.vue';

    import type { TenantData } from '@aspect/shared/types/generated';

    defineOptions({
        inheritAttrs: false,
    });

    defineProps<{
        tenant: TenantData;
        page: {
            sections: any[];
            [x: string]: any;
        };
    }>();

    const { globals } = useSite();
    const { siteRoute } = useRoute();
</script>
